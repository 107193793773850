import growGraph from '../../../Assets/Home/graph.png'
import growGraphMobile from '../../../Assets/Home/graph_mobile.png'
import styles from '../Home.module.scss'
import React, { useEffect, useState } from "react";

const GrowInvestment = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 767px)");
	
		// defining callbacks
		function handleMediaQueryChange(e) {
		  setIsMobile(e.matches);
		}
	
		mediaQuery.addEventListener("change", handleMediaQueryChange);
	
		// checking size initially
		setIsMobile(mediaQuery.matches);
	
		// Clean up by removing eventListener
		return () => {
		  mediaQuery.removeEventListener("change", handleMediaQueryChange);
		};
	  }, []);
	return (
		<>
			<div className={`h100 ${styles.growContainer}`}>
				<div className='row text-align-left'>
					<div className={`${styles.growContent} col-md-12`}>
						{/* <p>R.O.I. FOR ZIZI RAPID – R60</p> */}
						<h1>GROW YOUR INVESTMENT <span className={styles.multiple}>4X</span></h1>
					</div>
				</div>
				<div className='row '>
					<div className={`col-md-8`}>
						<img src={isMobile? growGraphMobile:growGraph} className={`img-responsive ${styles.growGraphImage}`} />
					</div>
					<div className={`col-md-4 ${styles.growItemsContainer}`}>
						<div className={styles.growItems}>
							GROW YOUR INVESTMENT 4X
						</div>
						<div className={styles.growItems}>
							{'R.O.I. > 30%'}
						</div>
						<div className={styles.growItems}>
							INCREASED FOOTFALL
						</div>
						<div className={styles.growItems}>
							APPRECIATING ASSET
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default GrowInvestment;